<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="780">
    <div class="flex ">
      <div class="menu">
        <div v-for="(item,index) in menuArr"
             :key='index'
             class="cursor-pointer"
             :class="{'current-menu':currentIndex == index}"
             @click.stop="onClickMenuItem(index)">
          {{item.name}}
        </div>
      </div>
      <div class="item-menu"
           v-if="currentIndex == 0">
        <div class=" cursor-pointer"
             v-for="(item,index) in itemMenuArr"
             :key='index'
             :class="{'current-menu':currentItemIndex == index}"
             @click.stop="onClickItemMenuItem(index)">{{item.name}}
        </div>
      </div>
      <div class="item-menu"
           v-if="currentIndex == 1">
        <div class=" cursor-pointer"
             v-for="(item,index) in carItemMenuArr"
             :key='index'
             :class="{'current-menu':currentCarItemIndex == index}"
             @click.stop="onClickCarItemMenuItem(index)">{{item.name}}
        </div>
      </div>
      <div class="modal-cont flex-1">
        <ul class="form-ul">
          <li class="form-ul-li"
              v-if="currentIndex == 0">
            <p class="li-title">收集点：</p>
            <MyTreeSelect ref="collectorPointTreeSelect"
                          :value="collectorPoint"
                          placeholder="收集点"
                          :data="collectorPointArr"
                          search
                          checked
                          style="width:100%;"
                          @onCheckChange="onChangeCollectorPoint">
            </MyTreeSelect>
          </li>
          <div class="car"
               v-if="currentIndex == 0">
            <div class="car-item"
                 v-for="(item,index) in collectorPointList"
                 :key="index"
                 :title="item.name"
                 @click.stop="">
              <span class="line1">{{item.name}}</span>
              <Icon custom="i-icon icon-guanbi"
                    size="16"
                    color="#6a6b75"
                    @click.stop="onClickDeleteCollectorPoint(index)" />
            </div>
          </div>
          <li class="form-ul-li half"
              v-if="currentIndex == 1">
            <p class="li-title">所属机构：</p>
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          search
                          style="width:100%"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 1">
            <p class="li-title">作业车辆：</p>
            <!-- 固定作业为单选，机动作业为多选 -->
            <MyTreeSelect :value="car"
                          placeholder="作业车辆"
                          :disabled='carDisabled'
                          :data="carTreeArr"
                          search
                          style="width:100%;"
                          class="half"
                          @onSelectChange="onChangeSelectCar">
            </MyTreeSelect>
          </li>
          <div class="car"
               v-if="currentIndex == 1">
            <div class="car-item"
                 v-for="(item,index) in carList"
                 :key="index"
                 @click.stop="">
              <span class="line1">{{item.name}}</span>
              <Icon custom="i-icon icon-guanbi"
                    size="16"
                    color="#6a6b75"
                    @click.stop="onClickDeleteCar(index)" />
            </div>
          </div>
          <li class="form-ul-li half"
              v-if="currentIndex == 2">
            <p class="li-title">作业班组：</p>
            <MyTreeSelect :value="group"
                          placeholder="班组名称"
                          :data="groupTreeArr"
                          search
                          style="width:100%;"
                          @onSelectChange="onChangeGroup">
            </MyTreeSelect>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 3">
            <p class="li-title">作业规则：</p>
            <Select v-model="ruleId"
                    label-in-value
                    @on-change="onChangeRule"
                    clearable>
              <Option v-for="item in ruleArr"
                      :value="item.workRuleId"
                      :key="item.workRuleId">{{ item.ruleName }}</Option>
            </Select>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 4">
            <p class="li-title">作业周期：</p>
            <Select v-model="period"
                    placeholder="作业周期"
                    class="input-right-5">
              <Option :value="item.id"
                      v-for="item in periodArr"
                      :key="item.id">{{item.name}}
              </Option>
            </Select>
            <Poptip trigger="hover"
                    content="计划运行周期"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 5">
            <p class="li-title">单次作业时长：</p>
            <Input v-model="time"
                   placeholder="单次作业时长"
                   maxlength="30"
                   v-intLimit
                   clearable
                   class="input-right-5"></Input>
            <span class="li-unit m-r-10">分钟</span>
            <Poptip trigger="hover"
                    content="单个考勤段内作业时长"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 6">
            <p class="li-title">有效时段：</p>
            <DatePicker type="date"
                        class="half"
                        disabled
                        style="margin-right:0"
                        :value="startTime"
                        :options="dateOptions"
                        :editable=false
                        @on-change="onChangeStart"
                        placeholder=""></DatePicker>
            <span style="margin: 0 10px;line-height:32px;">~</span>
            <DatePicker type="date"
                        class="half"
                        :value="endTime"
                        :options="dateOptions"
                        :disabled='endDateDisabled'
                        :editable=false
                        @on-change="onChangeEnd"
                        placeholder=""></DatePicker>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 7">
            <p class="li-title">备注：</p>
            <Input v-model="remark"
                   maxlength="-1"
                   :rows="3"
                   type="textarea"
                   placeholder="" />
          </li>
        </ul>
        <div class="flex j-center a-center">
          <Icon type="ios-alert-outline"
                size="20"
                color="#FF0000"
                class="m-r-5" />
          当前批量操作的原始信息将不显示，进行操作后将修改为相同内容。
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect,
    WorkTimeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '修改排班'
    },
    visible: Boolean,
    dataId: String,
    workType: Number
  },
  data () {
    return {
      loading: false,
      menuArr: [
        {
          name: '收集点'
        }, {
          name: '作业车辆'
        }, {
          name: '作业班组'
        }, {
          name: '作业规则'
        }, {
          name: '作业周期'
        }, {
          name: '单次作业时长'
        }, {
          name: '有效时间段'
        }, {
          name: '备注'
        }
      ],
      currentIndex: 0,
      itemMenuArr: [
        {
          name: '增加收集点'
        }, {
          name: '删除收集点'
        }
      ],
      currentItemIndex: 0,
      carItemMenuArr: [
        {
          name: '增加车辆'
        }, {
          name: '删除车辆'
        }
      ],
      currentCarItemIndex: 0,
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now()
        }
      },
      endDateDisabled: false,
      type: '',   // 固定作业，机动作业
      workBigType: [], // 作业类型
      workBigTypeId: [], // 作业类型
      departmentName: '',
      departmentId: '',
      car: '', // 作业车辆
      carId: '', // 作业车辆id
      driver: '', // 驾驶员
      driverId: '', // 驾驶员id
      carList: [], // 车辆列表
      collectorPointList: [],
      carAddList: [],     // 新增车辆列表
      carDeleteList: [],  // 删除车辆列表
      carDisabled: true,
      carTreeArr: [],
      group: '', // 作业班组
      groupId: '', // 作业班组
      rule: '', // 作业规则
      ruleId: '', // 作业规则
      period: '', // 作业周期
      collectorPoint: '',// 收集点
      collectorPointId: '',
      count: '', // 趟次
      oilLoss: '', // 油耗
      waterLoss: '', // 水耗
      workTime: '', // 作业时长
      startTime: '', // 有效时段
      endTime: '', // 有效时段
      remark: '', // 备注
      periodArr: [
        {
          id: 1,
          name: '每天'
        },
        {
          id: 2,
          name: '两天'
        },
        {
          id: 3,
          name: '三天'
        },
      ],
      collectorPointArr: [],
      ruleArr: [],
      groupTreeArr: [],
      workBigTypeArr: [
        {
          children: [],
          id: '74468e83218d866cb341232ffae4618a',
          itemlist: [],
          label: '清扫作业',
          name: '清扫作业',
          type: 'car',
          value: '74468e83218d866cb341232ffae4618a',
        },
        {
          children: [],
          id: '53616f89f4238e20b108eae870a8cdb2',
          itemlist: [],
          label: '洒水作业',
          name: '洒水作业',
          type: 'car',
          value: '53616f89f4238e20b108eae870a8cdb2',
        },
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible (newVal) {
      if (newVal) {
        this.getGroupList()
        this.getRuleList()
        this.getCollectorPointList()
      }
    },
    workType (newVal) {
      if (newVal) {
        this.type = newVal
      }
    },
    currentItemIndex () {
      this.collectorPointList = []
      this.collectorPointId = ''
      this.collectorPoint = ''
    },
    currentCarItemIndex () {
      this.carList = []
      this.carId = ''
      this.car = ''
    },
  },
  computed: {
    ...mapGetters({
      'gridNameTreeArr': 'getGridTreeList',
      departmentTreeArr: 'getDepartmentTreeList',
      //  collectorPointArr:"getCollectorPointList"
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateGridTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.updateGridTreeList()
      this.updateDepartmentTreeList()
      this.timeInit()
      this.getCarTreeList()
      this.getCollectorPointList()
    },
    getDetail () {
      // this.$store.dispatch('getCarDetail', this.dataId).then(res => {
      //   for (let i in res) {
      //     this[i] = res[i]
      //   }
      // })
      // this.collectorPointList = arr;
    },
    onClickMenuItem (i) {
      this.currentIndex = i
    },
    onClickItemMenuItem (index) {
      this.currentItemIndex = index
    },
    onClickCarItemMenuItem (i) {
      this.currentCarItemIndex = i
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      this.departmentId = section.id
      this.departmentName = section.name
      // 获取车辆列表
      this.carDisabled = false
      this.getCarTreeList()
    },
    // 获取车辆列表
    getCarTreeList (value) {
      let params = {
        carcategory: '74468e83218d866cb341232ffae4613a',
        depid: this.departmentId
      }
      this.$http.getCarDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.carTreeArr = res.result
        }
      })
    },
    // 收集点列表
    getCollectorPointList () {
      this.$http.getFacilityBigTypeAllList({ type: 'facl' }).then(res => {
        if (res.code === 200) {
          let index = res.result.findIndex(item => {
            return item.name == '收集点'
          })
          let params = {
            id: res.result[index].id
          }
          this.$http.getFacilityAllList(params).then(resItem => {
            if (resItem.code === 200) {
              this.collectorPointArr = resItem.result.content.list
            }
          })
        }
      })
    },
    // 选择收集表
    onChangeCollectorPoint (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'facl') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.collectorPointId = idArr.join()
      this.collectorPoint = valueArr.join()

      let dataArr = []
      section.forEach((item) => {
        if (item.type === "facl") {
          dataArr.push(item)
        }
      })
      dataArr.forEach(t => {
        let isFind = false;
        for (let i = 0; i < this.collectorPointList.length; i++) {
          if (t.id == this.collectorPointList[i].id) {
            isFind = true;
            break;
          }
        }
        if (!isFind) {
          this.collectorPointList.push(t);
        }
      })
    },
    // 删除收集点
    onClickDeleteCollectorPoint (i) {
      this.collectorPointList.splice(index, 1)
      this.car = this.collectorPointList.map(item => {
        return item.name
      }).join()
      this.carId = this.collectorPointList.map(item => {
        return item.id
      }).join()
    },
    // 选择车辆 单选
    onChangeSelectCar (section) {
      if (section.type == 'car') {
        this.carId = section.id
        this.car = section.name
        this.driverId = section.bindaccount
        this.driver = section.bindaccountname
        this.carList = [{
          id: section.id,
          name: section.name
        }]
      }
    },
    // 删除车
    onClickDeleteCar (index) {
      this.carList.splice(index, 1)
      this.car = this.carList.map(item => {
        return item.name
      }).join()
      this.carId = this.carList.map(item => {
        return item.id
      }).join()
    },
    // 获取作业班组列表
    getGroupList () {
      let params = {
        module: 4
      }
      this.$http.getWorkGroupItemAllList(params).then(res => {
        if (res.code === 200) {
          this.groupTreeArr = res.result.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children = item.children.map(items => {
                items.type = 'group'
                return items
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeGroup (section) {
      if (section.type == 'group') {
        this.groupId = section.workGroupId
        this.group = section.name
      }
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 4
      }
      this.$http.getWorkRuleList(params).then(res => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules
        }
      })
    },
    // 作业规则回调
    onChangeRule (data) {
      if (data) {
        this.rule = data.label
        this.ruleId = data.value
      }
    },
    // 有效时段开始时间
    onChangeStart (value) {
      this.startTime = value
    },
    // 有效时段结束时间
    onChangeEnd (value) {
      this.endTime = value
    },
    // 日期选择器赋初始值
    timeInit () {
      let time = new Date()
      // this.startTime = time.getFullYear() + '-01-01'
      this.startTime = getTomorrow()
      this.endTime = time.getFullYear() + '-12-31'
    },
    // 确定按钮
    onClickConfirm () {
      let carIds = [];
      this.carList.forEach((item) => {
        carIds.push(item.id);
      });
      let collectPointIds = [];
      this.collectorPointList.forEach((item) => {
        collectPointIds.push(item.id);
      });
      this.loading = true
      let params = {
        type: 2
      }
      if (this.dataId) params.carPlanIdList = this.dataId.split(',')
      switch (this.currentIndex) {
        case 0:
          params.collectorPointId = collectPointIds
          params.flag = this.currentItemIndex
          break;
        case 1:
          params.departmentId = this.departmentId
          params.carId = carIds
          params.flag = this.currentCarItemIndex
          break;
        case 2:
          params.groupId = this.groupId
          break;
        case 3:
          params.ruleId = this.ruleId
          break;
        case 4:
          params.period = this.period
          break;
        case 5:
          params.workTime = this.time
          break;
        case 6:
          params.startTime = this.startTime
          params.endTime = this.endTime
          break;
        case 7:
          params.remark = this.remark
          break;
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
      let collectorPointIds = [];
      this.collectorPointList.forEach((item) => {
        collectorPointIds.push(item.id);
      });
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.workBigType = []
      this.workBigTypeId = []
      this.car = ''
      this.carId = ''
      this.driver = ''
      this.driverId = ''
      this.collectorPoint = ''
      this.collectorPointId = ''
      this.collectorPointList = [];
      this.group = ''
      this.groupId = ''
      this.rule = ''
      this.ruleId = ''
      this.period = ''
      this.count = 2
      this.oilLoss = ''
      this.waterLoss = ''
      this.workTime = ''
      this.remark = ''
      this.status = 1
      // this.$refs.collectorPointTreeSelect.resetTreeData();
      this.timeInit()
      this.endDateDisabled = false
      this.departmentName = ''
      this.departmentId = ''
      this.currentIndex = 0
      this.currentCarItemIndex = 0
      this.carDisabled = true
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  padding: 10px 20px;
  width: 130px;
  border-right: 1px solid #bbbbbb;
  div {
    line-height: 25px;
  }
  .current-menu {
    color: #25bb96;
  }
}
.item-menu {
  padding: 10px 24px;
  width: 120px;
  border-right: 1px solid #bbbbbb;
  div {
    line-height: 30px;
  }
  .current-menu {
    color: #25bb96;
  }
}
.car {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 7px;
  margin: 0 20px;
  cursor: pointer;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .car-item {
    width: 85px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px 6px 0;
    padding-left: 4px;
    border: 1px solid #e1e4e6;
    border-radius: 3px;
  }
}
</style>