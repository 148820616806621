<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <!-- <div class="work-tip">
        <Icon custom="i-icon icon-tishi1"
              size="15"
              color="#ff9b77"
              class="m-r-5"
              style="line-height: 14px;" />
        固定网格：车辆按照网格进行清运，智能规划：系统会根据交通大数据、红绿灯等信息，自动规划车辆作业时间内的清运路线，智能规划仅适用于传统清运模式
      </div> -->
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业计划：</p>
          <Input v-model="plan"
                 placeholder="作业计划"
                 maxlength="30"
                 v-stringLimit />
        </li>
        <li class="form-ul-li a-center">
          <p class="li-title"></p>
          <Icon custom="i-icon icon-tishi1"
                size="16"
                color="#FF9B77"
                class="m-r-5"></Icon>
          此处作业计划将作为排班名称的前缀使用，例：作业计划-网格名称
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>网格名称：</p>
          <MyTreeSelect :value="gridName"
                        placeholder="网格名称"
                        :data="gridNameTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGridName">
          </MyTreeSelect>
        </li> -->
        <li class="form-ul-li person-input">
          <p class="li-title"><span class="li-must">*</span>网格名称：</p>
          <MyTreeSelect ref="gridTreeSelect"
                        :data="gridNameTreeArr"
                        search
                        checked
                        style="width: 100%"
                        @onCheckChange="onChangeGridName">
            <div class="person">
              <div class="person-item"
                   v-for="(item, index) in gridList"
                   :key="index"
                   @click.stop="">
                <span class="line1">{{ item.name }}</span>
                <Icon custom="i-icon icon-guanbi"
                      size="16"
                      color="#6a6b75"
                      @click.stop="gridList.splice(index, 1)" />
              </div>
            </div>
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>收集点：</p>
          <MyTreeSelect :value="collectorPoint"
                        placeholder="收集点"
                        :data="collectorPointArr"
                        search
                        checked
                        style="width:100%;"
                        @onCheckChange="onChangeCollectorPoint">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        style="width:100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half workcar">
          <p class="li-title"><span class="li-must">*</span>作业车辆：</p>
          <MyTreeSelect :value="car"
                        placeholder="作业车辆"
                        :disabled='!departmentId'
                        :data="carTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeSelectCar">
          </MyTreeSelect>
          <!-- <Input v-model="driver"
                 placeholder="驾驶员"
                 readonly /> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业班组：</p>
          <MyTreeSelect :value="group"
                        placeholder="作业班组"
                        :data="groupTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGroup">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业规则：</p>
          <Select v-model="ruleId"
                  label-in-value
                  @on-change="onChangeRule"
                  clearable>
            <Option v-for="item in ruleArr"
                    :value="item.workRuleId"
                    :key="item.workRuleId">{{ item.ruleName }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业周期：</p>
          <Select v-model="period"
                  placeholder="作业周期">
            <Option :value="item.id"
                    v-for="item in periodArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">单次作业时长：</p>
          <Input v-model="workTime"
                 placeholder="单次作业时长"
                 maxlength="30"
                 v-intLimit
                 clearable
                 class="input-right-5" />
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>有效时段：</p>
          <DatePicker type="date"
                      class="half"
                      style="margin-right:0"
                      :value="startTime"
                      :options="dateOptions"
                      :disabled='startDateDisabled'
                      :editable=false
                      @on-change="onChangeStart"
                      placeholder=""></DatePicker>
          <span style="margin: 0 10px;line-height:32px;">~</span>
          <DatePicker type="date"
                      class="half"
                      style="margin-right:0"
                      :value="endTime"
                      :options="dateOptions"
                      :disabled='endDateDisabled'
                      :editable=false
                      @on-change="onChangeEnd"
                      placeholder=""></DatePicker>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- <div class="work-tip">
        <Icon custom="i-icon icon-tishi1"
              size="15"
              color="#ff9b77"
              class="m-r-5"
              style="line-height: 14px;" />
        传统清运：车辆逐点一一清运；智能清运为垃圾桶（收集点）绑定满溢监测之后，垃圾桶满溢报警后，清运车辆针对满溢报警的垃圾桶(收集点)进行清运；
        <p class="li-title">
          注：智能清运只针对绑定有满溢设备的垃圾桶（收集点）
        </p>
      </div> -->
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading='loading'
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '排班'
    },
    dataId: String
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          // return date && date.valueOf() < Date.now()
          return false
        }
      },
      startDateDisabled: false,
      endDateDisabled: false,
      loading: false,
      plan: '', // 作业计划
      gridName: '', // 网格名称
      gridNameId: '', // 网格名称id
      gridList: [],
      collectorPoint: '', // 收集点
      collectorPointId: '',
      car: '',   // 车辆名称
      carId: '',  // 车辆名称id
      driver: '',  // 驾驶员
      driverId: '', // 驾驶员id
      group: '',  // 班组
      groupId: '',  // 班组id
      rule: '', // 作业规则
      ruleId: '',
      period: '',  // 周期
      workTime: '', // 单次作业时间
      startTime: '',  // 开始时间
      endTime: '',  // 结束时间
      remark: '', // 备注
      // 车辆树列表
      departmentName: '',
      departmentId: '',
      status: 1,
      driverTreeArr: [],
      groupTreeArr: [],
      ruleArr: [],
      carTreeArr: [],
      periodArr: [
        {
          id: 1,
          name: '每天'
        },
        {
          id: 2,
          name: '两天'
        },
        {
          id: 3,
          name: '三天'
        },
      ],
      collectorPointArr: []
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    },
    visible (newValue) {
      if (newValue) {
        this.getGroupList()
        this.getRuleList()
        this.getCollectorPointList()
      }
    }
  },
  computed: {
    ...mapGetters({
      'gridNameTreeArr': 'getGridTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateGridTreeList',
      'updateDepartmentTreeList',
    ]),
    init () {
      this.updateGridTreeList()
      this.updateDepartmentTreeList()
      this.timeInit()
    },
    getDetail () {
      this.$store.dispatch('getMechanicalCleanPlanDetail', {
        carPlanId: this.dataId,
        type: 2
      }).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        console.log(res);
        this.carId = res.carId.join()
        this.car = res.car.join()
        this.collectorPointId = res.collectorPointId.join()
        this.collectorPoint = res.collectorPoint.join()
        if (!this.isBeyondDate(this.startTime)) {
          this.startDateDisabled = true
        }
        if (!this.isBeyondDate(this.endTime)) {
          this.endDateDisabled = true
        }
        // this.collectorPoint = res.collectorPoint.split(',')
        // this.collectorPointId = res.collectorPointId.split(',')
        this.getCarTreeList()
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      this.departmentId = section.id
      this.departmentName = section.name
      // 获取车辆列表
      this.getCarTreeList()
    },
    // 获取车辆列表
    getCarTreeList () {
      let params = {
        carcategory: '74468e83218d866cb341232ffae4613a',
        depid: this.departmentId
      }
      this.$http.getCarDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.carTreeArr = res.result
        }
      })
    },
    // 是否超出日期
    isBeyondDate (date) {
      return formatDate(new Date()) < date
    },
    // 收集点列表
    getCollectorPointList () {
      this.$http.getFacilityBigTypeAllList({ type: 'facl' }).then(res => {
        if (res.code === 200) {
          let index = res.result.findIndex(item => {
            return item.name == '收集点'
          })
          let params = {
            id: res.result[index].id
          }
          this.$http.getFacilityAllList(params).then(resItem => {
            if (resItem.code === 200) {
              this.collectorPointArr = resItem.result.content.list
            }
          })
        }
      })
    },
    // 选择收集点
    onChangeCollectorPoint (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'facl') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.collectorPointId = idArr.join()
      this.collectorPoint = valueArr.join()
    },
    // 日期选择器赋初始值
    timeInit () {
      let time = new Date()
      // this.startTime = time.getFullYear() + '-01-01'
      this.startTime = getTomorrow()
      this.endTime = time.getFullYear() + '-12-31'
    },
    // 选择开始日期
    onChangeStart (value) {
      this.startTime = value
    },
    // 选择结束日期
    onChangeEnd (value) {
      this.endTime = value
    },
    // 选择网格
    onChangeGridName (section) {
      let dataArr = []
      section.forEach((item) => {
        if (item.type != "depart") {
          dataArr.push(item)
        }
      })
      dataArr.forEach(t => {
        let isFind = false;
        for (let i = 0; i < this.gridList.length; i++) {
          if (t.id == this.gridList[i].id) {
            isFind = true;
            break;
          }
        }
        if (!isFind) {
          this.gridList.push(t);
        }
      })
    },
    // 选择车辆
    onChangeSelectCar (section) {
      if (section.type == 'car') {
        this.carId = section.id
        this.car = section.name
        this.driverId = section.bindaccount
        this.driver = section.bindaccountname
      }
    },
    // 获取作业班组列表
    getGroupList () {
      let params = {
        module: 4
      }
      this.$http.getWorkGroupItemAllList(params).then(res => {
        if (res.code === 200) {
          this.groupTreeArr = res.result
          this.groupTreeArr.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children.map(subItem => {
                subItem.type = 'group'
                return subItem
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeGroup (section) {
      if (section.type == 'group') {
        this.groupId = section.workGroupId
        this.group = section.name
      }
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 4
      }
      this.$http.getWorkRuleList(params).then(res => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules
        }
      })
    },
    // 作业规则回调
    onChangeRule (data) {
      if (data) {
        this.rule = data.label
        this.ruleId = data.value
      }
    },
    // 点击确认
    onClickConfirm () {
      if (!this.plan) return this.$Message.info('请输入作业计划');
      if (!this.gridList.length) return this.$Message.info('请选择网格');
      if (!this.collectorPointId) return this.$Message.info('请选择收集点');
      if (!this.car) return this.$Message.info('请选择作业车辆');
      if (!this.group) return this.$Message.info('请选择作业班组');
      if (!this.period) return this.$Message.info('请输入作业周期');
      if (!this.startTime) return this.$Message.info('请选择有效时段');
      if (!this.endTime) return this.$Message.info('请选择有效时段');
      if (this.startTime > this.endTime) return this.$Message.info('请选择正确的有效时段')
      this.loading = true
      let gridIds = []
      this.gridList.forEach((item) => {
        gridIds.push(item.id)
      })
      let repeatName = {
        planName: this.plan,
        gridIdList: gridIds,
        module: 1,
        type: 2
      }
      this.$http.repeatCarPlanName(repeatName).then((res) => {
        if (res.result.length) {
          this.loading = false
          this.$Message.info({
            content: '作业计划名称重复'
          })
        } else {
          // 创建
          this.createPlan()
        }
      })
    },
    createPlan () {
      let gridIds = []
      this.gridList.forEach((item) => {
        gridIds.push(item.id)
      })
      let params = {
        plan: this.plan,
        // gridName: this.gridName,
        // collectorPoint: this.collectorPoint,
        collectorPointId: this.collectorPointId.split(','),
        ruleId: this.ruleId,
        // rule: this.rule,
        carId: this.carId.split(','),
        // car: this.car,
        // driverId: this.driverId,
        // driver: this.driver,
        groupId: this.groupId,
        // group: this.group,
        period: this.period,
        workTime: this.workTime,
        startTime: this.startTime,
        endTime: this.endTime,
        remark: this.remark,
        status: this.status,
        departmentId: this.departmentId,
        type: 2,
      }
      if (this.dataId) {
        params.carPlanId = this.dataId
        params.gridNameId = this.gridNameId
      } else {
        params.gridNameIdList = gridIds
        params.module = 1
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新下拉框
        // this.$store.dispatch('updateCarManufacturerAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetDate()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetDate () {
      this.plan = ''
      this.gridName = ''
      this.gridNameId = ''
      this.gridList = []
      this.collectorPoint = ''
      this.collectorPointId = ''
      this.car = ''
      this.carId = ''
      this.driver = ''
      this.driverId = ''
      this.group = ''
      this.groupId = ''
      this.rule = ''
      this.ruleId = ''
      this.period = ''
      this.workTime = ''
      this.remark = ''
      this.status = 1
      this.timeInit()
      this.startDateDisabled = false
      this.endDateDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: none;
  overflow-y: visible;
}
.work-tip {
  background-color: #e1e4e6;
  color: #6c6d6e;
  padding: 6px;
  margin-bottom: 20px;
  font-size: 12px;
  p {
    color: #ff0d0d;
  }
}
.person-input {
  .relative {
    width: 100%;
    ::v-deep {
      // .ivu-input {
      //   height: 100px;
      // }
      // .treeSelect-dropdown {
      //   .ivu-input {
      //     height: 32px;
      //   }
      // }
      .ivu-tree-title {
        line-height: 32px;
      }
    }
  }
}

.person {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 7px;
  cursor: pointer;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .person-item {
    width: 85px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px 6px 0;
    padding-left: 4px;
    border: 1px solid #e1e4e6;
    border-radius: 3px;
  }
}
</style>
